<template>
  <a-row class="content-fix" type="flex">
    <!-- {{ $route.params.id }} -->
    <!-- {{ langTab }} -->
    <!-- {{ form.id }} -->
    <spinner v-if="loading" />
    <a-col v-show="!loading" class="height100 flex-1">
      <!-- {{ form.content }} -->
      <a-row class="btn-row">
        <button
          class="tab-btn"
          :class="{ active: tab == 'content' }"
          @click="tab = 'content'"
        >
          <a-icon type="project" />{{ $t("ingradient") }}
        </button>

        <button
          class="tab-btn"
          :class="{ active: tab == 'photo' }"
          @click="tab = 'photo'"
        >
          <a-icon type="picture" />{{ $t("Img") }}
        </button>
        <button
          class="tab-btn"
          :class="{ active: tab == 'document' }"
          @click="tab = 'document'"
        >
          <a-icon type="file" />{{ $t("Documents") }}
        </button>
      </a-row>
      <div v-show="tab == 'content'">
        <!-- lang tabs -->
        <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
          <a-col :span="4" class="form_title"> {{ $t("Lang") }}: </a-col>
          <a-col :span="18">
            <!-- lang translit tabs -->
            <a-row align="middle" class="flex-baseline flex-wrap">
              <lang-tabs v-model="langTab" class="mb-1x" />
              <!-- <a-button v-if="langTab == 'oz'" @click.stop="translitAction">
                Перевести копию на
                {{ langTab == "uz" ? "кирилицу" : "латиницу" }}
              </a-button> -->
            </a-row>
          </a-col>
        </a-row>

        <!-- title -->
        <a-row
          :class="{ 'form-group--error': emptyTitle }"
          align="middle"
          class="flex-baseline"
        >
          <a-col :span="4" class="form_title">{{ $t("title") }}:</a-col>
          <a-col :span="20" class="sm-mb">
            <a-input
              v-model="form.title[langTab]"
              :placeholder="$t('EnterTitle')"
              class="form_title-input"
            />
            <div v-if="isTitleNull" class="error-text">
              {{ $t("EditorErrTitle") }}
            </div>
          </a-col>
        </a-row>

        <!-- Теги -->
        <!-- <a-row align="middle" class="flex-baseline">
          <a-col :span="4" style=" padding-right: 8px">
            Теги:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-select
              v-model="form.tags"
              mode="multiple"
              placeholder="Выберите категорию"
              :filter-option="filterOption"
              show-search
              option-filter-prop="children"
              style="width: 100%"
              @change="handleChange"
            >
              <a-select-option
                v-for="t in filteredOptions"
                :key="t.id"
                :value="t.id"
              >
                {{ t.title }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row> -->
        <!-- published time -->
        <a-row style="margin-bottom: 15px">
          <a-col :span="4" class="form_title"> {{ $t("publicationDate") }}: </a-col>
          <a-col :span="12">
            <a-date-picker
              v-model="form.pub_date"
              valueFormat="DD.MM.YYYY"
              format="DD.MM.YYYY"
            />
          </a-col>
        </a-row>
        <!-- store image modal -->
        <a-modal
          :visible="modalVisible"
          :title="$t('ImageStorages')"
          :closable="false"
          style="max-height: 500px; max-width: 900px"
          width="900px"
        >
          <imageStorage @pasteHandler="editorInstance.insertContent(arguments[0])" />
          <template slot="footer">
            <a-button key="back" @click="modalVisible = false">
              {{ $t("Close") }}
            </a-button>
          </template>
        </a-modal>

        <!-- Теги -->
        <!-- <a-row align="middle" class="flex-baseline">
          <a-col :span="4" style=" padding-right: 8px">
            Выберите меню:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-select
              v-model="form.menu"
              mode="multiple"
              placeholder="Выберите категорию"
              :filter-option="filterOption"
              show-search
              option-filter-prop="children"
              style="width: 100%"
              @change="handleChangeMenu"
            >
              <a-select-option v-for="t in menuList" :key="t.id" :value="t.id">
                {{ t.title }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row> -->

        <!-- status toggle -->
        <a-row style="margin-top: 25px">
          <a-col :span="4" class="form_title"> {{ $t("Status") }}: </a-col>
          <a-switch v-model="form.status" />
        </a-row>
        <a-row style="margin-top: 25px">
          <a-col :span="4" class="form_title"> {{ $t('ReleaseHome') }}: </a-col>
          <a-switch v-model="form.on_slider" />
        </a-row>

        <a-row v-if="form.on_slider" align="middle" class="flex-baseline">
          <a-col :span="4" class="sm-mb" />
          <a-col :span="12" class="sm-mb">
            <div class="form_field">
              <div class="deputy_image">
                <img :src="slider_image" />
              </div>
              <a-button
                style="font-size: 13px"
                type="primary"
                @click="uploadFile"
              >
                <a-icon style="font-size: 16px" type="upload" /> Upload
                Image</a-button
              >
              <span v-if="!slider_image" style="padding-left: 8px"
                >Картинка не выбрана</span
              >
              <input
                ref="fileInput"
                class="UploadhideInput"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/svg"
                @change="handleFile"
              />
            </div>
          </a-col>
        </a-row>

        <!-- editor -->
        <a-row
          :class="{ 'form-group--error': emptyContent }"
          style="margin-top: 25px"
          type="flex"
        >
          <a-col :span="4" class="form_title" style="margin-bottom: 15px">
            {{ $t("Content") }}:
          </a-col>
          <a-col v-show="langTab == 'oz'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.oz"
              :initial-value="form.content.oz"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: $t('ImageStorages'),
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.content.oz = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              {{ $t("EditorErrTitle") }}
            </div>
          </a-col>
          <a-col v-show="langTab == 'uz'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.uz"
              :initial-value="form.content.uz"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: $t('ImageStorages'),
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.content.uz = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              {{ $t("EditorErrTitle") }}
            </div>
          </a-col>
          <a-col v-show="langTab == 'qr'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.qr"
              :initial-value="form.content.qr"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: $t('ImageStorages'),
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.content.qr = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              {{ $t("EditorErrTitle") }}
            </div>
          </a-col>
          <a-col v-show="langTab == 'ru'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.ru"
              :initial-value="form.content.ru"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: $t('ImageStorages'),
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.content.ru = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              {{ $t("EditorErrTitle") }}
            </div>
          </a-col>
          <a-col v-show="langTab == 'en'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.en"
              :initial-value="form.content.en"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: $t('ImageStorages'),
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.content.en = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              {{ $t("EditorErrTitle") }}
            </div>
          </a-col>
        </a-row>
      </div>
      <!-- photo tab -->
      <div v-show="tab == 'photo'">
        <!-- upload photo -->
        <a-row align="middle">
          <a-col :span="3" class="form_title"> {{ $t("TablePhotos") }} </a-col>
          <a-col :span="20">
            <image-upload
              v-if="!imageUploading"
              :list="photoList"
              :selected-image="main_image"
              accept="image/jpeg, image/png"
              method-name="news"
              multiple
              upload-multiple
              :with-cropper="false"
              has-method
              @change="handleImages"
              @drag-update="updateFilesPosition"
              @set-main-image="activeImage"
              @delete="deleteImage"
            />
          </a-col>
        </a-row>
      </div>

      <!-- document tab  -->
      <div v-show="tab == 'document'">
        <h4 v-if="pending">{{ $t("Loading") }}</h4>

        <span v-show="!pending">
          <a-row style="margin-bottom: 15px; margin-top: 15px">
            <a-col :span="4" class="form_title">{{ $t("documentFiles") }}:</a-col>
            <a-col :span="18">
              <a-button @click="$refs.documentRef.click()">
                <input
                  ref="documentRef"
                  accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                  hidden
                  type="file"
                  multiple
                  @change="handeDocumentInput"
                />
                <a-icon type="upload" />{{ $t("FileSelect") }}
              </a-button>

              <template v-if="document_files_array != 0">
                <p style="padding-left: 15px">{{ $t("Downloads") }}</p>
                <InputFileItem
                  v-for="item in document_files_array"
                  :key="item.uid"
                  :file="item.binary_file"
                  class="mt-2 no-a-hover"
                  @delete="deleteDocumentFileFromState(item)"
                />

                <a-row style="border-bottom: 1px solid #ddd; padding-bottom: 15px" />
              </template>
            </a-col>
          </a-row>
        </span>
      </div>

      <span v-if="form.id" slot="tabBarExtraContent">
        <a-popconfirm
          placement="bottomRight"
          title="Вы действительно хотите удалить данную новость?"
          @confirm="deletePost"
        >
          <a-button :disabled="pending" style="margin-right: 10px">
            <a-icon type="delete" />{{ $t("Delete") }}
          </a-button>
        </a-popconfirm>
      </span>

      <a-row
        v-if="tab == 'content'"
        class="form-buttons__action"
        style="margin-top: 20px"
        type="flex"
      >
        <a-col :span="4" style="padding-right: 8px" />
        <a-col>
          <a-button
            :disabled="pending"
            @click="
              $router.push({
                name: 'plenary-sessions',
                params: { id: $route.query.menu },
              })
            "
          >
            <a-icon type="close" />{{ $t("Cancel") }}
          </a-button>

          <a-button
            :loading="pending"
            style="margin-left: 10px"
            type="primary"
            @click="submitNews((noRouterBack = true))"
          >
            <a-icon type="check" />
            {{ form.id ? $t("Save") : "Добавить" }}
          </a-button>
          <a-button
            :loading="pending2"
            style="margin-left: 10px"
            type="primary"
            @click="submitNews((noRouterBack = false))"
          >
            <a-icon type="check" />
            {{ form.id ? $t("SaveAndExit") : "Добавить" }}
          </a-button>
        </a-col>
      </a-row>
    </a-col>

    <spoiler-modal
      v-show="!!spoiler.modalVisibility"
      v-model="spoiler"
      @ok="appendToEditor"
      @cancel="closeSpoilerModal"
    />
  </a-row>
</template>

<script>
import InputFileItem from "@/components/utils/input-file-item";
import cloneDeep from "lodash/cloneDeep";

// import VueTagsInput from "@johmun/vue-tags-input"
import spoiler from "@/mixins/spoiler.js";

const newsDefaultFields = () => ({
  title: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
  },
  slug: "",
  position: 1,
  status: true,
  on_slider: false,
  content: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
  },
  is_main: false,
  pub_date: null,
  images: [],
  slider_image: "",
});

export default {
  name: "News",

  components: {
    "image-upload": () => import("@/components/utils/image-upload"),
    imageStorage: () => import("@/components/imageStorage/imageStorage"),
    spoilerModal: () => import("@/components/directory/modal-components/modal-spoiler"),
    editor: () => import("@tinymce/tinymce-vue"),
    InputFileItem,
    // VueTagsInput
  },

  mixins: [spoiler],

  data() {
    return {
      commentsList: null,
      slider_image: "",
      tab: "content",
      activeFilter: "",
      filters: [
        { text: "Все", value: "" },
        { text: "Новые", value: "pending" },
        { text: "Активные", value: "approved" },
        { text: "Блокированные", value: "blocked" },
      ],
      columns: [
        {
          title: this.$t("TagsColTitle"),
          key: "title",
          class: "wanted-fio-td",
          scopedSlots: { customRender: "name" },
          width: "90%",
        },
        {
          title: this.$t("UsersColTitle2"),
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      // eslint-disable-next-line no-undef
      langTab: $langPrefix,
      imageUploading: false,
      form: { ...newsDefaultFields() },
      pub_date: null,
      contest_end_date: null,
      groupCategoryList: [],
      fileList: [],
      image_List: [],
      pending: false,
      pending2: false,
      loading: false,
      menuList: [],
      childMenuList: [],
      parent_id: null,
      child_id: null,

      previewVisible: false,
      previewImage: "",

      tabPane: null,
      main_image: null,
      video_input: {
        file_type: 3,
        file: "",
      },

      video_file: null,
      file_List: [],

      newsFiles: [],
      document_file: null,
      document_files_array: [],
      errors: {
        slug: "",
      },
      tag: "",
      autocompleteItems: [],
      tagTimer: null,
      emptyTitle: false,
      emptyContent: false,

      current_slug: "",

      modalVisible: false,
      tagList: [],
      selectedTags: [],
      inputTags: [],
    };
  },

  computed: {
    isTitleNull() {
      return !!(
        !this.form.title.uz &&
        !this.form.title.oz &&
        !this.form.title.ru &&
        !this.form.title.en
      );
    },
    isContentNull() {
      return !!(
        !this.form.content.uz &&
        !this.form.content.oz &&
        !this.form.content.ru &&
        !this.form.content.en
      );
    },
    photoList() {
      return this.form && this.form.images;
    },
    getKey() {
      return this.langTab;
    },
    // filteredOptions() {
    //   return this.tagList.filter((o) => !this.selectedTags.includes(o.title))
    // }
  },

  watch: {
    $route: {
      handler() {
        this.fetchData();
      },
      immediate: true,
    },
    parent_id(newVal) {
      this.findChild(newVal);
    },
  },

  async created() {
    this.loading = true;

    this.form = {
      ...newsDefaultFields(),
    };
    this.loading = false;
  },

  mounted() {
    // this.fetchMenuList();
    // this.getTagList();
  },

  methods: {
    // handleChange(e) {
    //   this.form.tags = [];
    //   this.tagList.forEach((item) => {
    //     e.includes(item.id) && this.form.tags.push(item.id);
    //   });
    // },
    // handleChangeMenu(e) {
    //   this.form.menu = [];
    //   this.menuList.forEach((item) => {
    //     e.includes(item.id) && this.form.menu.push(item.id);
    //   });
    // },
    uploadFile() {
      this.$refs.fileInput.click();
    },
    handleFile(e) {
      this.slider_image = URL.createObjectURL(e.target.files[0]);
      this.form.slider_image = e.target.files[0];
    },
    async deletePost() {
      try {
        const res = await this.$store.dispatch(
          "posts/deletePost",
          this.$route.params && this.$route.params.id
        );
        if (res && res.status == 204) {
          // const f = this.categoryProps.news.findIndex(
          //   (elem) => elem.id == item.id
          // )

          // if (f != -1) {
          //   this.categoryProps.news.splice(f, 1)
          // }
          this.$router.push({
            name: "plenary-sessions",
            // params: { id: this.$route.query.menu }
          });
        }
      } catch (e) {
        this.$sentry.captureMessage(e);
      }
    },
    async getTagList() {
      let res = await this.$api.get("/admin/post/tag/list/");
      res && res.data && (this.tagList = res.data);
    },
    async deleteImage(val) {
      let res = await this.$api.delete("/admin/post-file/" + val);
      console.log(res);
      res && res.data && res.data.status && this.fetchDataImages();
    },
    openStoreModal() {
      this.modalVisible = true;
    },
    async removeItem() {
      try {
        const r = await this.$store.dispatch("news/deleteItemAction", this.form.id);
        if (r) {
          this.$message.success("Новость успешно удалена");
          this.$router.push({
            name: "plenary-sessions",
            // params: { id: this.$route.query.menu }
          });
        }
      } catch (err) {
        console.log(err);
        this.$sentry.captureMessage(err);
      }
    },
    translitAction() {
      try {
        const from = this.langTab,
          to = this.langTab == "uz" ? "oz" : "uz",
          methodName = this.langTab == "uz" ? "toKirill" : "toLatin";

        if (this.form.title[from]) {
          this.form.title[to] = this[methodName](this.form.title[from]);
        }

        if (this.form.content[from]) {
          this.form.content[to] = this[methodName](this.form.content[from]);
        }
      } catch (error) {
        this.$sentry.captureMessage(error);
      }
    },
    update(newTags) {
      this.autocompleteItems = [];
      this.form.tags = newTags;
    },
    updateFilesPosition(files) {
      let promises = [];
      // let otherImages = new FormData()
      let images_order = [];
      // const mainImage = this.fileList.find(
      //   (item) => item.id && item.uzid === this.main_image
      // )
      Array.from(files)
        .filter((item) => item.id && item.uzid !== this.main_image)
        .map((item, index) => {
          // console.log("item", item);
          // console.log("index", index);
          images_order = [...images_order, { image_id: item.id, order: index + 1 }];
        });
      promises.push(this.$api.patch("/admin/post/image/order/update/", { images_order }));

      Promise.all(promises)
        .then(
          () => {},
          (err) => {
            console.log(err);
          }
        )
        .then(() => {
          this.$message.success("Позиции успешно сохранены");
        });
    },

    check2Null(arg) {
      return Object.values(arg).every((item) => !item);
    },
    validateForm() {
      if (this.check2Null(this.form.title)) {
        this.emptyTitle = true;
        this.$message.error("Введите заголовок на одном из языков");
        return false;
      }
      if (this.check2Null(this.form.content)) {
        this.emptyContent = true;
        this.$message.error("Введите контент на одном из языков");
        return false;
      }

      return true;
    },
    async submitNews(noRouterBack) {
      if (!this.validateForm()) return;

      if (this.pending) return;

      if (noRouterBack == true) {
        this.pending = true;
      } else {
        this.pending2 = true;
      }

      try {
        let newData = cloneDeep(this.form);
        newData = {
          ...newData,
          pub_date: newData.pub_date,
        };

        let f = new FormData();
        if (newData.slider_image && typeof newData.slider_image != "string") {
          f.append("slider_image", newData.slider_image);
        }
        Object.keys(newData).forEach((key) => {
          newData[key] &&
            key != "content" &&
            key != "title" &&
            key != "images" &&
            key != "slider_image" &&
            f.append(key, newData[key]);
          key == "status" && f.append(key, newData[key]);
          key == "on_slider" && f.append(key, newData[key]);
          key == "title" && f.append(key, JSON.stringify(newData[key]));
          key == "content" && f.append(key, JSON.stringify(newData[key]));
        });

        // this.document_files_array.forEach((item) => {
        //   item &&
        //     item.binary_file &&
        //     !item.binary_file.id &&
        //     f.append("docs", item.binary_file, item.binary_file.name)
        // })
        const createOrUpdate = {
          url:
            this.form && this.form.id
              ? "/admin/post/" + this.form.id
              : "/admin/post/create/",
          method: this.form && this.form.id ? "put" : "post",
          data: f,
        };
        if (
          !(await this.$api[createOrUpdate.method](
            createOrUpdate.url,
            createOrUpdate.data,
            { timeout: 0 }
          ).then((res) => {
            if (res.data && res.data.status && res.data.data && res.data.data.id) {
              const { id } = res.data && res.data.data;
              this.form = { ...this.form, id };
              return true;
            }
          }))
        ) {
          if (noRouterBack == true) {
            this.pending = false;
          } else {
            this.pending2 = false;
          }
          return;
        }
        // upload after post done
        this.uploadPostFiles(noRouterBack);
      } catch (error) {
        console.error(error);
        this.$message.error(
          "Возникла проблема при " +
            (this.form && this.form.id ? "сохранении" : "добавлении") +
            " новости" +
            "\r\n\r\n" +
            error
        );

        this.$sentry.captureMessage(error);
      }
    },
    async uploadPostFiles(noRouterBack = true) {
      this.pending = true;

      try {
        // upload images
        await this.uploadImages();
        this.pending = false;
        this.$message.success("Новость успешно сохранено");
        await this.fetchData();
        if (!noRouterBack) {
          // this.$router.push({
          //   name: "category",
          //   params: { cat_id: this.form.category_id }
          // })
          this.$router.push({
            name: "plenary-sessions",
            // params: { id: this.$route.query.menu }
          });
        }
      } catch (error) {
        this.pending = false;
        this.$sentry.captureMessage(error);
      }
    },

    uploadImages() {
      let f = new FormData();
      this.fileList.forEach((item) => {
        f.append("images", item);
      });
      this.$api.post;
    },

    fetchData() {
      this.loading = true;

      if (isNaN(this.$route.params.id)) {
        this.loading = false;
        return;
      }

      this.$api
        .get("/admin/post/" + this.$route.params.id)
        .then(async (res) => {
          if (res && res.data) {
            const { data } = res.data;
            this.form = {
              ...this.form,
              ...data,
              title: data.title || {},
              content: data.content || {},
              pub_date: data.pub_date,
            };
            this.slider_image = this.form.slider_image;
            this.form.images =
              this.form &&
              this.form.images &&
              this.form.images.map((image) => {
                return {
                  ...image,
                  checked: false,
                };
              });
            this.current_slug = this.form.slug;
            this.form.docs &&
              this.form.docs.forEach((item) => {
                this.document_files_array = [
                  ...this.document_files_array,
                  {
                    binary_file: item,
                    uid: Math.random().toString().slice(2),
                  },
                ];
              });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error("Ошибка при получении данных!!!");
          this.loading = false;
          return err;
        });
    },

    fetchNewsFiles() {
      if (!this.$route.params.id) {
        return;
      }

      return this.$api
        .get("/news/file/list/" + this.$route.params.id)
        .then((res) => {
          this.newsFiles = (res.data || []).slice().sort((a, b) => b.updated - a.updated);

          // set first element of video files as video_url
          if (
            this.videoUrl &&
            this.videoUrl.length &&
            this.videoUrl[0] &&
            this.videoUrl[0].file
          ) {
            this.video_input = this.videoUrl[0];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async handleImages(arg) {
      this.imageUploading = true;
      this.fileList = arg;
      this.image_List = arg;
      let newImages = arg.filter((item) => item && !item.id);
      for (let i = 0; i < newImages.length; i++) {
        let f = new FormData();
        f.append("post_id", this.$route.params.id);
        f.append("type", 2);
        f.append(
          "file",
          newImages[i] && newImages[i].fileTarget,
          newImages[i] && newImages[i].fileTarget && newImages[i].fileTarget.name
        );

        await this.$api.post("/admin/post-file/create", f);
      }
      // if (count == newImagesLength) {
      await this.fetchDataImages();
      this.imageUploading = false;
      // }
    },

    async fetchDataImages() {
      this.document_files_array = [];
      await this.$api
        .get("/admin/post/" + this.$route.params.id)
        .then((res) => {
          if (res && res.data) {
            const { data } = res.data;
            this.form = {
              ...this.form,
              images: data.images,
            };
            this.form.images =
              this.form &&
              this.form.images &&
              this.form.images.map((image) => {
                return {
                  ...image,
                  checked: false,
                };
              });
            data &&
              data.docs &&
              data.docs.forEach((item) => {
                console.log(item);
                this.document_files_array.push({
                  binary_file: item,
                  uid: Math.random().toString().slice(2),
                });
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // fetchMenuList() {
    //   this.$store.dispatch("menu/fetch").then((res) => {
    //     this.menuList = res;
    //   });
    // },
    async activeImage(e) {
      this.photoList.forEach((item, index) => {
        this.photoList[index].main = item.id == e;
      });
      let res = await this.$api.post("/admin/post-file/active/" + e);
      res && res.data.status && this.fetchDataImages();
    },
    async deleteDocumentFileFromState(arg) {
      if (this.form.id) {
        try {
          const status = await this.$store.dispatch("file/delete", arg.binary_file.id);
          if (status) {
            await this.fetchDataImages();
          }
        } catch (err) {
          this.$sentry.captureMessage(err);
        }
      } else {
        this.document_files_array = this.document_files_array.filter(
          (el) => el.uid != arg.uid
        );
      }
    },
    async handeDocumentInput(e) {
      if (this.form.id) {
        if (e.target.files && e.target.files != 0) {
          let f = new FormData();
          f.append("post_id", this.$route.params.id);
          f.append("type", 1);
          Array.from(e.target.files).forEach((item) => {
            f.append("file", item);
          });
          let { data } = await this.$api.post("/admin/post-file/create", f);
          if (data && data.status) {
            await this.fetchDataImages();
          }
        }
      } else {
        if (e.target.files && e.target.files != 0) {
          Array.from(e.target.files).forEach((item) => {
            this.document_files_array = [
              ...this.document_files_array,
              {
                binary_file: item,
                uid: Math.random().toString().slice(2),
              },
            ];
          });
          // console.log(this.document_files_array);
        }
      }
      this.$refs.documentRef.value = null;
    },
  },
};
</script>
<style>
.ant-modal {
  max-height: 600px;
}

.scroll-container {
  padding: 20px 0px;
}

input[type="datetime-local"] {
  border-radius: 2px;
  padding: 4px;
  border: 1px solid #c7c7c7;
}

.flex-end {
  justify-content: flex-end;
}
.form_field > .deputy_image {
  width: 280px;
  height: 150px;
  border: 1px solid grey;
  border-radius: 4px;
  overflow: hidden;
  display: inline;
  margin-right: 30px;
}

.form_field > .deputy_image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form_field {
  margin: 10px 0;
  height: 150px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
